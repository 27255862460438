<template>
  <b-card
    no-body
  >
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">
        <b-form-group
          class="mr-1 mb-md-0"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="ClearVal()"
          >
            <feather-icon
              icon="PlusIcon"
              size="12"
            />
            เพิ่มกลุ่มหวย
          </b-button>
        </b-form-group>
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
    <b-table
      striped
      hover
      responsive
      class="position-relative items-center"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
    >
      <template #cell(index)="data">
        {{ perPage * (currentPage - 1) + (data.index + 1) }}
      </template>
      <template #cell(picture)="data">
        <b-avatar
          size="lg"
          :src="data.item.picture"
        />
      </template>
      <template #cell(ip)="data">
        <div class="text-nowrap">
          <a
            :href="`http://${data.item.ip}`"
            target="_blank"
          >{{ data.item.ip }}</a>

        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'dashboard', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          /> -->
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="updateData(data.item)"
          />
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="TrashIcon"
            size="16"
            class="mx-1"
            @click="deleteData(data.item.id)"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <b-modal
      v-model="modalShow"
      title="กลุ่มหวย"
      hide-footer
    >
      <b-form-group
        label="ชื่อกลุ่มหวย"
        label-for="vi-first-name"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="PlusIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="vi-first-name"
            v-model="Data.name"
            placeholder="ระบุชื่อกลุ่มหวย"
          />
        </b-input-group>
      </b-form-group>
      <hr>
      <div class="text-center">
        <b-button
          v-if="Data.id"
          variant="gradient-warning"
          class="mr-1"
          @click="saveData()"
        >
          อัพเดท
        </b-button>
        <b-button
          v-else
          variant="gradient-primary"
          class="mr-1"
          @click="saveData()"
        >
          บันทึก
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="ClearVal()"
        >
          ยกเลิก
        </b-button>

      </div>
    </b-modal>
  </b-card>
</template>

<script>
// import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import {
  BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // vSelect,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      modalShow: false,
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'name', label: 'ชื่อกลุ่มหวย' },
        { key: 'actions', label: 'Action' },
      ],
      items: [],
      Data: {
        id: null,
        name: null,
      },
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
    this.getData()
  },
  methods: {
    saveData() {
      const formData = {
        name: this.Data.name,
      }
      if (this.Data.id) {
        console.log('update')
        console.log(formData)
        this.$http
          .put(`https://staffapi.ma5lotto.com/api/lotto/group/${this.Data.id}`, formData)
          .then(() => {
            this.Success('อัพเดตสำเร็จ')
            this.getData()
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        console.log('save')
        console.log(formData)
        this.$http
          .post('https://staffapi.ma5lotto.com/api/lotto/group', formData)
          .then(() => {
            this.Success('บันทึกสำเร็จ')
            this.getData()
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
      this.ClearVal()
    },
    updateData(Val) {
      this.modalShow = true
      this.Data = Val
    },
    getData() {
      this.$http
        .get('https://staffapi.ma5lotto.com/api/lotto/group')
        .then(response => {
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    deleteData(id) {
      this.$swal({
        title: 'ยืนยันการลบข้อมูล',
        text: 'คุณต้องการลบข้อมูลนี้หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            .delete(`https://staffapi.ma5lotto.com/api/lotto/group/${id}`)
            .then(response => {
              this.getData()
              if (response.data) {
                this.$swal({
                  icon: 'success',
                  title: 'ลบข้อมูลสำเร็จ',
                  text: 'ข้อมูลของคุณถูกลบแล้ว',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => console.log(error))
        } else {
          this.$swal('ยกเลิก', '', 'success')
        }
      })
    },
    ClearVal() {
      this.modalShow = !this.modalShow
      this.Data = {
        id: null,
        name: null,
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
